import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);


// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './about.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import NavigationIcons from "../../components/shared/NavigationIcons/NavigationIcons";
// import NavigationIconsItem from "../../components/shared/NavigationIcons/NavigationIconsItem";
// import CarsIconGreen from "../../images/icons/car_g.svg";
// import DatingSitesIconGreen from "../../images/icons/dating_g.svg";
// import OnlineSaleIconGreen from "../../images/icons/online_sales_g.svg";
// import PremiumBrandsIconGreen from "../../images/icons/premium_brands_g.svg";
// import RealEstateIconGreen from "../../images/icons/real_estate_g.svg";
// import RestorationIconGreen from "../../images/icons/restoration_g.svg";
// import SharingIconGreen from "../../images/icons/sharing_g.svg";
// import WebPublishingIconGreen from "../../images/icons/web_publishing_g.svg";
// import { getRoutes } from '../../components/shared/routes';

// const InspirationAbout = () => {
//   const { t } = useTranslation();
// //   const pageInternalName = 'subpage12';
//   const pageTitle = `${getRoutes().InspirationAbout.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;
//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Use cases'}/>

//       <div className={css.wrapper}>

//         <div className={css.InspirationsAbout}>
//         <div className={css.wrapper}>
//         <NavigationIcons
//             title="We impact the Growth of your business efficiency"
//             titleMargin={48}
//         >
//             <NavigationIconsItem
//                 image={RestorationIconGreen}
//                 text="Aged Photos"
//                 page={'AgedPhoto'}
//             />
//             <NavigationIconsItem
//                 image={CarsIconGreen}
//                 text="Automotive"
//                 page={'Automotive'}
//             />
//             <NavigationIconsItem
//                 image={DatingSitesIconGreen}
//                 text="Dating Sites"
//                 page={'DatingSites'}
//             />
//             <NavigationIconsItem
//                 image={OnlineSaleIconGreen}
//                 text="Online Sales"
//                 page={'OnlineSales'}
//             />
//             <NavigationIconsItem
//                 image={PremiumBrandsIconGreen}
//                 text="Premium Brands"
//                 page={'PremiumBrands'}
//             />
//             <NavigationIconsItem
//                 image={RealEstateIconGreen}
//                 text="Real Estate"
//                 page={'RealEstate'}
//             />
//             <NavigationIconsItem
//                 image={SharingIconGreen}
//                 text="Sharing"
//                 page={'Sharing'}
//             />
//             <NavigationIconsItem
//                 image={WebPublishingIconGreen}
//                 text="Web Publishing"
//                 page={'WebPublishing'}
//             />
//         </NavigationIcons>
//     </div>
//         </div>

//         <div className={css.Footer}>
//           <Footer s/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default InspirationAbout;
